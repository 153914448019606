<template>
  <div class="tw_approval_list receive_history" :class="{ empty: !items }">
    <tw-collapse :title="$t('Label.Receive History')" :initial-open="true">
      <template v-slot:header-inner>
        <div class="header-inner">
          {{ $t('Label.Receive History') }}
          <el-button v-if="historyDownloadable" type="text" @click="visibleHistoryDownloadDialog = true">{{ $t('Button.History Download') }}</el-button>
        </div>
      </template>
      <div class="approval_list">
        <div class="approval_list_item" v-for="(item, index) in items" :key="index">
          <header>
            <div class="column">
              <div class="user">
                <figure class="avatar table"><img :src="item.userIcon" v-default-src="'user'"></figure>
                <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{item.userName}}</div>
                  <div class="from_name">{{item.userName}}</div>
                </el-tooltip>
              </div>
              <div class="between">
                <div class="date">{{item.receiveDate | dateTimeFormat}}</div>
              </div>
            </div>
            <div class="uploads" @click="openDrawer(item)">
              {{formatNumber(item.uploads) || 0}} (OK {{formatNumber(item.successes) || 0}} / Error {{formatNumber(item.errors) || 0}}) File:
            </div>
          </header>
        </div>
        <div v-if="items !== null && !items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
        <div class="skeleton" v-if="items === null"><el-skeleton :rows="3" animated /></div>
      </div>
    </tw-collapse>

    <el-drawer class="drawer" :visible.sync="drawerShow" :withHeader="false" size="800px">
      <tw-collapse :title="$t('Label.Receive History')" :initial-open="true">
        <div class="approval_list">
          <div class="approval_list_item">
            <div class="column">
              <div class="user">
                <figure class="avatar table"><img :src="drawerItem.userIcon" v-default-src="'user'"></figure>
                <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{drawerItem.userName}}</div>
                  <div class="from_name">{{drawerItem.userName}}</div>
                </el-tooltip>
              </div>
              <div class="between">
                <div class="date">{{drawerItem.receiveDate | dateTimeFormat}}</div>
              </div>
            </div>
            <div class="uploads">
              {{formatNumber(drawerItem.uploads) || 0}} (OK {{formatNumber(drawerItem.successes) || 0}} / Error {{formatNumber(drawerItem.errors) || 0}}) File:
            </div>
          </div>
        </div>
        <div class="error_list" v-if="drawerItem.errorFiles && drawerItem.errorFiles.length">
          <div class="head">Error</div>
          <div class="error_item" v-for="(item, index) in drawerItem.errorFiles" :key="index">
            <div class="name">{{item.errorFileName}}</div>
            <div class="message">{{item.errorContents}}</div>
          </div>
        </div>
        <div class="circle" @click="close" />
      </tw-collapse>
    </el-drawer>
    <tw-download-range-select-dialog
      v-if="historyDownloadable"
      :visible.sync="visibleHistoryDownloadDialog"
      ref="downloadRangeDialog"
      @download="$emit('download', $event)"
    />
  </div>
</template>

<script>
import TwCollapse from '@/components/molecules/TwCollapse';
import TwDownloadRangeSelectDialog from '@/components/organisms/TwDownloadRangeSelectDialog.vue'
import { $api } from '@/store/ApiClient';
import { formatNumber } from '@/utils/searchUtil.js';
import dayjs from 'dayjs';

export default {
  name: 'TwReceiveHistory',
  props: {
    permitInfo: String,
    historyDownloadable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    TwCollapse,
    TwDownloadRangeSelectDialog
  },
  data() {
    return {
      formatNumber: formatNumber,
      drawerShow: false,
      drawerItem: {},
      items: null,
      visibleHistoryDownloadDialog: false,
    };
  },
  computed: {},
  created() {
    this.fetch();
  },
  methods: {
    openDrawer(item) {
      this.drawerShow = !this.drawerShow
      this.drawerItem = item
    },
    close() {
      this.drawerShow = false
    },
    fetch() {
      // 本日から1年前の期間を設定する
      const today = dayjs().startOf('day');
      const monthAgo = today.subtract(1, 'month')
      // bff_ncp_12 輸出許可書・輸入許可書プール登録状況検索
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/nc-pool-datas/common/reference',
          query: {
            dateOfDisplayFrom: monthAgo.format('YYMMDD'),
            dateOfDisplayTo: today.format('YYMMDD'),
            permitInfo: this.permitInfo, // 許可書区分
          }
        }
      };
      $api.request(params)
      .then(res => {
        this.items = res || [];
      })
      .catch(err => {
        this.items = [];
        if (err.statusCode !== 403) this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.tw_approval_list {
  margin: 40px 0 16px;
  width: 50%;
  & .header-inner {
    display: flex;
    justify-content: space-between;
  }
  .approval_list {
    max-height: 410px;
    overflow-y: auto;
    padding: 0 16px;
  }
  .approval_list_item {
    border-bottom: 1px solid $color_gray_300;
    padding: 12px;
  }
  header {
    display: flex;
    align-items: center;
    .column {
      margin-right: 24px;
    }
    .user {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80px;
      }
    }
    .between {
      display: flex;
      justify-content: space-between;
    }
    .date, .action {
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_600;
    }
    .uploads {
      font-size: 14px;
      color: $color_dark_blue;
      cursor: pointer;
    }
  }
  .empty_message {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color_black;
    margin-top: 33px;
    margin-bottom: 20px;
  }
  .skeleton {
    margin: 8px;
  }
}
</style>
<style lang="scss">
.tw_approval_list.receive_history {
  &.empty .wrap.open header {
    border-bottom: 0;
  }
  .drawer {
    .wrap {
      padding: 16px 8px 16px;
      height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    header {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: $color_black;
      padding-bottom: 16px;
    }
    footer {
      display: none;
    }
    .approval_list {
      max-height: auto;
      overflow-y: auto;
      padding: 0 16px;
    }
    .approval_list_item {
      display: flex;
      align-items: center;
    }
    .column {
      margin-right: 32px;
    }
    .user {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
      }
      .avatar {
        width: 32px;
        height: 32px;

      }
    }
    .between {
      display: flex;
      justify-content: space-between;
    }
    .date, .action {
      font-size: 12px;
      line-height: 14px;
      color: $color_gray_600;
    }
    .uploads {
      font-size: 14px;
      color: $color_black;
      cursor: normal;
    }

    .error_list {
      padding: 16px 16px 0px;
      .head {
        font-size: 18px;
        font-weight: bold;
        color: $color_gray_800;
        margin-bottom: 16px;
      }
      .error_item {
        border-radius: 6px;
        padding: 12px 16px;
        background: #F9F9FD;
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 1.75;
        .name {
          margin-bottom: 8px;
        }
      }
    }
    .circle {
      position: absolute;
      top: 14px;
      left: -18px;
      width: 18px;
      height: 29px;
      background: $color_gray_800 url(../../assets/images/icons/chevron_right_white.svg) no-repeat 3px center;
      background-size: 16px auto;
      border-radius: 15px 0 0 15px;
      z-index: 11;
      cursor: pointer;
      &:hover {
        opacity: .5;
      }
    }
  }
  .el-drawer,
  .el-drawer__body {
    overflow: visible;
  }
}
</style>
