<template>
  <el-dialog
    class="tw-permit-history-download-range-select-dialog"
    :title="$t('Label.Select Date Range')"
    :visible="visible"
    custom-class="download-range-dialog"
    @update:visible="$emit('update:visible', $event)"
  >
    <el-radio-group v-model="dateType" size="small">
      <el-radio-button label="dateRange">Date Range</el-radio-button>
      <el-radio-button label="month">Month</el-radio-button>
    </el-radio-group>
    <el-form ref="form" :rules="rules">
      <el-form-item v-show="dateType === 'dateRange'" prop="downloadDateRange">
        <el-date-picker
          v-model="downloadDateRange"
          popper-class="within-31-days"
          type="daterange"
          range-separator="to"
          :format="`yyyy${dateFormatSeparator}MM${dateFormatSeparator}dd`"
          :value-format="valueFormat"
          start-placeholder="Start date"
          end-placeholder="End date"
          clearable
          :pickerOptions="{
            onPick,
            disabledDate
          }">
        </el-date-picker>
      </el-form-item>
      <el-form-item v-show="dateType === 'month'">
        <el-date-picker
          class="month-select"
          v-model="downloadMonth"
          type="month"
          popper-class='month_date_picker'
          placeholder="month"
          :format="`yyyy${dateFormatSeparator}MM`"
          clearable
        >    
        </el-date-picker>
      </el-form-item>
    </el-form>
    <template v-slot:footer><el-button type="primary" :disabled="!historyDownloadable" @click="$emit('download', selectedRange)">Download</el-button></template>
  </el-dialog>
</template>

<script>
import { onDateRangeChange } from '@/utils/searchUtil.js';
import dayjs from 'dayjs';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    dateFormatSeparator: {
      type: String,
      required: false,
      default: '-'
    },
    valueFormat: {
      type: String,
      required: false,
      default: 'yyyy-MM-dd'
    }
  },
  data(){
    return {
      onDateRangeChange,
      dateType: 'dateRange',
      defaultDate: dayjs().subtract(1, 'month').toDate(),
      downloadMinDate: null,
      downloadDateRange: [],
      downloadMonth: '',
      rules: {
        downloadDateRange: [
          {
            message: '31日間以内で入力してください',
            validator: (rule, value, callback) => {
              if(this.check31DaysOver()) {
                callback(new Error(rule.message));
              } else {
                callback();
              }
            },
            trigger: 'change',
          }
        ],
      }
    }
  },
  computed: {
    historyDownloadable() {
      if(this.dateType === 'month') return !!this.downloadMonth
      if(!this.downloadDateRange?.[0] || !this.downloadDateRange?.[1]) return false;
      return !this.check31DaysOver();
    },
    selectedRange() {
      if(this.dateType === 'dateRange') return this.downloadDateRange;
      return [
        dayjs(this.downloadMonth).startOf('month').format(this.valueFormat.toUpperCase()),
        dayjs(this.downloadMonth).endOf('month').format(this.valueFormat.toUpperCase())
      ]
    }
  },
  methods: {
    onPick(dates) {
      if (dates.maxDate) this.downloadMinDate = null;
      else this.downloadMinDate = dates.minDate;
    },
    disabledDate(date) {
      if (this.downloadMinDate) {
        return this.check31DaysOver(this.downloadMinDate, date);
      }
      return false;
    },
    check31DaysOver(date1 = this.downloadDateRange?.[1], date2 = this.downloadDateRange?.[0]) {
      return Math.abs(dayjs(date1).diff(dayjs(date2), 'day')) > 30;
    },
    reset() {
      this.downloadMinDate = null;
      this.downloadDateRange = [];
      this.downloadMonth = '';
      this.dateType = 'dateRange';
      this.$refs?.form?.resetFields();
    }
  }
}
</script>

<style lang="scss" scoped>
.tw-permit-history-download-range-select-dialog {
  &::v-deep .download-range-dialog {
    margin-top: 30vh !important;
    width: max-content;
    min-width: 300px;

    & .el-dialog__body {
      padding-bottom: 0;
      & .el-radio-group {
        margin-bottom: 16px;
      }
      & .el-date-editor {
        &.month-select {
          & .el-input__inner {
            box-shadow: unset;
          }
        }
      }
    }
    & .el-dialog__footer {
      padding-right: 0;
    }
  }
}
</style>

<style lang="scss">
.el-popper.within-31-days {
  .el-date-table td.disabled:hover:after {
    display: block;
    position: absolute;
    width: auto;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    z-index: 3;
    text-align: center;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    background: #5E5873;
    padding: 1px 8px;
    border-radius: 11px;
    white-space: nowrap;
  }
  .el-date-table td.disabled:hover::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    bottom: calc(100% - 5px);
    margin: 0 auto;
    z-index: 2;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid #5E5873;
    border-bottom: 0;
  }
}
</style>