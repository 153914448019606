<template>
  <div class="permit_detail_inner pool_detail" :class="{ permit_history: isPermitHistory }" ref="inner">
    <header :class="{ deleted: isDelete }">
      <h1 v-if="isPermitHistory">{{`${processType === PROCESS_TYPE.ECPERMIT ? 'Export' : 'Import'} Customs Permit`}}</h1>
      <h1 v-else>{{$t(`Label.${processType === PROCESS_TYPE.ECPERMIT ? 'Export' : 'Import'} Customs Permit`)}}</h1>
      <img v-if="isDelete" class="delete" src="@/assets/images/delete.png">
      <el-dropdown v-if="res" class="drawer" trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
        <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
        <el-dropdown-menu class="permit_drawer nowrap">
          <template v-if="isPermitHistory">
            <el-dropdown-item command="txt">TXT Download</el-dropdown-item>
            <el-dropdown-item v-if="!isLinking && !isDelete && editableFlag" command="delete">Delete</el-dropdown-item>
          </template>
          <template v-else>
            <el-dropdown-item command="txt">{{$t('Label.TXT Download')}}</el-dropdown-item>
            <el-dropdown-item v-if="!isLinking && !isDelete && editableFlag" command="delete">{{$t('Label.Delete')}}</el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
    </header>

    <tw-button v-if="res" type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
      <img src="@/assets/images/icons/view_all.svg">
    </tw-button>

    <table class="card">
      <template v-if="res">
        <tw-process-list :isPermitHistory="isPermitHistory" v-for="table in filteredSchemas" :table="table" :key="table.name" :initialOpen="true" />
      </template>
    </table>

    <div v-if="isLinking" class="card _linking">
      <div class="item">
        <div class="title">Trading/FlowID</div>
        <div class="value">{{getTradingFlowId()}}</div>
      </div>
      <div class="item">
        <div class="title">Trading Name</div>
        <div class="value">{{res.tradingName}}</div>
      </div>
      <div class="item">
        <div class="title">Flow Name</div>
        <div class="value">{{res.flowName}}</div>
      </div>
      <!-- TODO: Ph3で非表示に 次Ph以降で対応 -->
      <!-- <div class="item">
        <div class="title">Invoice No</div>
        <div class="value">{{res.invoiceNo}}</div>
      </div> -->
      <div class="item process">
        <div class="title">Process</div>
        <div class="value" :class="{ link: !isPermitHistory }" @click="link">{{res.process}}</div>
      </div>
      <div class="item">
        <div class="title">From - To</div>
        <div class="value flex">
          <div class="company">
            <figure class="avatar">
              <img :src="res.fromCompanyIcon" v-default-src="'user'">
            </figure>
            <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
              <div slot="content">
                <div class="company_name">{{res.fromOfficialCompanyName}}<br />{{res.fromCompanySection}}</div>
              </div>
              <p class="name">{{res.fromOfficialCompanyName}}</p>
            </el-tooltip>
          </div>
          <div class="while">-</div>
          <div class="company">
            <figure class="avatar">
              <img :src="res.toCompanyIcon" v-default-src="'user'">
            </figure>
            <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
              <div slot="content">
                <div class="company_name">{{res.toOfficialCompanyName}}<br />{{res.toCompanySection}}</div>
              </div>
              <p class="name">{{res.toOfficialCompanyName}}</p>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div v-show="res" class="attachment">
      <div class="attach">
        <div>Attach File</div>
      </div>
      <div v-if="res && res.pdfFilePath" class="file">
        <div class="inner">
          <div class="user">
            <figure class="avatar table"><img :src="res.pdfAttachedUserIcon"></figure>
            <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
              <div slot="content">{{res.pdfAttachedUserName}}</div>
              <div class="from_name">{{res.pdfAttachedUserName}}</div>
            </el-tooltip>
          </div>
          <a class="file_name" @click.prevent="download('PDF')" tabindex="-1">{{res.pdfFileName}}</a>
          <span class="meta">{{res.pdfFileSize | fileSize}},&nbsp;{{res.pdfAttachedDate | dateFormat}}</span>
        </div>
      </div>
    </div>

    <tw-to-top v-if="isPermitHistory && mounted" :container="$refs.inner" class="detail-to-top" />
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import TwProcessList from '@/components/organisms/TwProcessList';
import TwToTop from '@/components/atoms/TwToTop';
import TwButton from '@/components/atoms/TwButton';
import { PROCESS_TYPE, PROCESS_ID, DELETE_FLG, PERMIT_LINK_FLG } from 'lib-tw-common';
import ecSchemas from '@/dictionaries/processes/ecpermit.json';
import icSchemas from '@/dictionaries/processes/icpermit.json';
// import { createPermitData } from '@/utils/createPermitData';
import { createGoodsLineSeparate } from '@/utils/createGoodsLine';
import { formatNumberString } from '@/utils/searchUtil.js';

// スタブ
// import ecPermitStub from '@/../public/ecPermitExample.json';
// import icPermitStub from '@/../public/icPermitExample.json';

export default {
  name: 'TwPermitDetailInner',
  components: {
    TwProcessList,
    TwToTop,
    TwButton,
  },
  provide() {
    return {
      s: this,
      activeTab: null
    }
  },
  props: {
    declarationNo: String,
    declarationDate: [String, Number],
    trx: String,
    tradingId: String,
    processType: String,
    isPermitHistory: {
      type: Boolean,
      default: false,
    },
    editableFlag: Boolean,
  },
  data() {
    return {
      res: null,
      PROCESS_TYPE: PROCESS_TYPE,
      schemas: this.processType === PROCESS_TYPE.ECPERMIT ? ecSchemas : icSchemas,
      expand: true,
      mounted: false,
    };
  },
  computed: {
    // スキーマを表示するテーブルのみにフィルタ NOTE: 輸出(輸入)通関個別、商品個別 remarksGrpは除く
    filteredSchemas() {
      let tables = _.cloneDeep(this.schemas.tables)
      tables = _.filter(tables, table => {
        return ['exportCustomsSeparate', 'importCustomsSeparate', 'goodsLineSeparate'].includes(table.variableName);
      });
      _.forEach(tables, table => {
        table.groups = _.filter(table.groups, group => {
          return !['remarksGrp'].includes(group.variableName);
        });
      });
      // console.log(tables)
      return tables;
    },
    // 商品個別表示用のデータを生成します（プロセス詳細）
    goodsLineSeparatePreviewItems() {
      if (!_.get(this.res, 'goodsLineSeparate.goods')) return [];
      const ret = _.map(this.res.goodsLineSeparate.goods, goods => {
        // console.log(goods)
        return {
          hsCode: this.processType === PROCESS_TYPE.ECPERMIT ? goods.exportGoodsDetailsGrp.hsCode : goods.importGoodsDetailsGrp.importHsCode,
          goodsName: goods.commonGoodsDetailsGrp.goodsName,
          quantity1: formatNumberString(goods.commonGoodsDetailsGrp.quantity1),
          quantity1UnitId: goods.commonGoodsDetailsGrp.quantity1UnitId,
          quantity2: formatNumberString(goods.commonGoodsDetailsGrp.quantity2),
          quantity2UnitId: goods.commonGoodsDetailsGrp.quantity2UnitId,
          amount: formatNumberString(this.processType === PROCESS_TYPE.ECPERMIT ? goods.exportGoodsDetailsGrp.declarationAmount : goods.importGoodsDetailsGrp.customsAmount),
          goodsCode: _.get(goods, 'importGoodsDetailsGrp.goodsCode'),
          originCountry: _.get(goods, 'importGoodsDetailsGrp.originCountry'),
          tariffRate: _.get(goods, 'importGoodsDetailsGrp.tariffRate'),
          tariff: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.tariff')),
          tariffExemptionValue: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.tariffExemptionValue')),
          consumptionTaxRate1: _.get(goods, 'importGoodsDetailsGrp.consumptionTaxRate1'),
          consumptionTaxValue1: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.consumptionTaxValue1')),
          consumptionTaxExemptionValue1: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.consumptionTaxExemptionValue1')),
          currency: 'JPY',
          goodsLineSeparateSeq: goods.keyGrp.goodsLineSeparateSeq,
        }
      });
      // console.log(ret)
      return ret;
    },
    // 削除済かどうか
    isDelete() {
      return _.get(this.res, 'deleteFlag') === String(DELETE_FLG.DELETED);
    },
    // 紐付け済かどうか
    isLinking() {
      return _.get(this.res, 'linkingFlag') === String(PERMIT_LINK_FLG.ON);
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    async fetch() {
      // bff_ncp_3 輸出許可書プール詳細照会BFF
      // bff_ncp_7 輸入許可書プール詳細照会BFF
      const apiCode = this.processType === PROCESS_TYPE.ECPERMIT ? 'get_/v1/nc-pool-datas/export/detail' : 'get_/v1/nc-pool-datas/import/detail';
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: apiCode,
          query: {
            declarationNo: this.declarationNo,
            declarationDate: this.declarationDate,
            trx: this.trx,
          }
        }
      };
      $api.request(params)
      .then(res => {
        this.res = res;
        this.res.permitLatest = { declarationNo: true };
        // 許可書データ
        // const processData = createPermitData({res: this.res, processType: this.processType});
        if (this.processType === PROCESS_TYPE.ECPERMIT) {
          this.$set(this.res, 'exportCustomsSeparate', {
            exPermitNotifyDataGrp: res,
          });
        } else {
          if (_.get(this.res, 'oilCoalTariffCertNo')) {
            // oilCoalTariffCertNo → oilcoalTariffCertNo 変換対応 TODO:不要になったら削除
            this.res.oilcoalTariffCertNo = _.get(this.res, 'oilCoalTariffCertNo');
          }
          this.$set(this.res, 'importCustomsSeparate', {
            imPermitNotifyDataGrp: res,
          });
        }
        // 許可書商品データ
        const goods = createGoodsLineSeparate({
          entityId: this.res.entityId || '',
          processId: this.processType === PROCESS_TYPE.ECPERMIT ? PROCESS_ID.ECPERMIT : PROCESS_ID.ICPERMIT,
          processSeq: parseInt(this.res.processSeq) || '',
          goods: this.res.goods || [],
        });
        this.$set(this.res, 'goodsLineSeparate', {
          goods: goods,
        });
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    // プロセス詳細へ遷移
    link() {
      // プロセス詳細小窓から開いていた場合は、ドロワーを閉じる
      if (this.isPermitHistory) {
        this.$emit('close');
        return;
      }
      // プール一覧から開いている場合は、プロセス詳細へ遷移
      this.$router.push({
        name: `Process${_.capitalize(this.res.processId.replace(/\d/g, ''))}`,
        params: { entityId: this.res.entityId, processSeq: this.res.processSeq, _processId: this.res.processId.toLowerCase() },
      })
    },
    // ドロップダウンメニュー
    onDropDownClick(command) {
      if (command === 'txt') this.download();
      else {
        this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          this.deletePermit();
        })
        .catch(() => {});
      }
    },
    // TXT or PDFダウンロード
    download(type = 'TXT') {
      const query = {
        declarationNo: this.declarationNo,
        declarationDate: this.declarationDate,
        trx: this.trx,
        downloadFileType: type, // TXT or PDF
      }
      let fileName;
      if (type === 'PDF') fileName = this.res.pdfFileName || this.$options.filters.fileName(this.res.pdfFilePath);
      else fileName = this.$options.filters.fileName(this.res.permitFilePath) || `${this.declarationNo}.txt`;
      // bff_ncp_4 輸出許可書プール詳細ダウンロードBFF
      // bff_ncp_8 輸入許可書プール詳細ダウンロードBFF
      const apiCode = this.processType === PROCESS_TYPE.ECPERMIT ? 'get_/v1/nc-pool-datas/export/download' : 'get_/v1/nc-pool-datas/import/download';
      $api
      .txtDownload(query, fileName, 'tw-pooldata-bff-api', apiCode)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 削除BFF
    deletePermit() {
      this.$store.commit('START_PROCESS');
      // bff_ncp_9 輸出許可書プール削除BFF
      // bff_ncp_10 輸入許可書プール削除BFF
      const apiCode = this.processType === PROCESS_TYPE.ECPERMIT ? 'post_/v1/nc-pool-datas/export/delete' : 'post_/v1/nc-pool-datas/import/delete';
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: apiCode,
          query: {
            declarationNo: this.declarationNo,
            declarationDate: this.declarationDate,
          }
        }
      };
      $api.request(params)
      .then(async () => {
        this.$emit('deleted');
        // await this.fetch();
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
        this.$store.commit('END_PROCESS');
      });
    },
    getTradingFlowId() {
      const tradingId = this.tradingId;
      const flowId = this.res.tradingFlowId;
      return `${tradingId ? tradingId + '/' : ''}${flowId || ''}`;
    },
  }
}
</script>

<style lang="scss" scoped>
.permit_detail_inner {
  padding: 30px 35px;
  position: relative;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 24px;
    }

    .delete {
      margin: -30px 32px 0 auto;
    }

    &.deleted {
      flex-wrap: wrap;
      .drawer.el-dropdown {
        // width: 100%;
        .el-dropdown-link.ellipsis {
          margin: 0 16px 0 auto;
        }
      }
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .column {
      height: 41px;
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      &:last-child {
        justify-content: flex-end;
      }
    }
    .tw_entity_icon {
      margin-right: 8px;
    }
    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      margin-right: 8px;
    }
    .tw_status_icon {
      margin-right: 24px;
    }
    .tw_button {
      margin-right: 24px;
    }
  }
  table.card {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0;
    overflow: hidden;
    margin-bottom: 24px;
    // box-shadow: 0px 4.11278px 8.22556px rgba(170, 170, 204, 0.5);
    &.excustom {
      margin-bottom: 32px;
      ::v-deep .tw_process_list > .inner {
        display: none;
      }
    }
  }
  .card._linking {
    margin: 40px 0 24px;
    padding: 24px;
    box-shadow: none;
    border: 1px solid $color_gray_300;
    border-radius: 6px;
    .item {
      display: flex;
      font-size: 14px;
      margin-bottom: 24px;
      &:hover {
        background: white;
        .value {
          color: $color_black;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        width: 40%;
        color: $color_gray_800;
      }
      .value.flex {
        display: flex;
        align-items: center;
        .while {
          margin: 0 16px;
        }
      }
      .company {
        display: flex;
        align-items: center;
        figure.avatar {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
        .name {
          max-width: 58px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.process {
        .value {
          color: $color_dark_blue;
          &.link {
            cursor: pointer;
          }
        }
      }
    }
  }

  &.permit_history {
    height: 100vh;
    overflow-y: scroll;
  }

  .attachment {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
    .file {
      position: relative;
      margin-top: 16px;
      border-radius: 6px;
      padding: 12px 16px;
      background: $color_gray_100;
      &:first-child {
        margin-top: 32px;
      }
    }
    .inner {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .user {
      max-width: 200px;
      margin-right: 24px;
      display: flex;
      align-items: center;
      color: $color_black;
      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    a.file_name {
      // max-width: 710px;
      color: $color_dark_blue;
      margin-right: 16px;
      cursor: pointer;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
<style lang="scss">
.el-dropdown-menu.el-popper.permit_drawer {
  z-index: 3000 !important;
}
</style>
